import * as React from 'react'
import { Link, graphql } from 'gatsby'

const PrimaryArticle = ({ data }) => {
    return (
        data.map((node, i) => (
          <Link to={`/article/${node.slug}`} key={i}>
                <div className="flex flex-col md:flex-row md:space-x-12">
                    <div className="flex-1 flex items-center order-2 md:order-1">
                        <div className="p-0 md:p-6">
                            <h1 className="text-2xl">{node.title_en}</h1>
                            <p>{node.subtitle_en}</p>
                        </div>
                    </div>
                    <div className="flex-1 order-1 md:order-2 mb-6 md:mb-0">
                        <img src={node.image != null ? node.image : "" } alt="placeholder" />
                    </div>
                </div>
            </Link>
        ))
    )
}

const SecondaryArticle = ({ data }) => {
  return (
      data.map((node, i) => (
        <div className="flex flex-row space-x-12 border-t border-b" key={i}>
        <div className="flex-1 flex items-center">
            <div className="p-6">
              <Link to={`/article/${node.slug}`}>
                    <p className="mb-3 text-skin-muted">
                      {node.category_en}
                    </p>
                    <h1 className="text-lg font-medium">
                      {node.title_en}
                    </h1>
                    <p>
                      {node.subtitle_en}
                    </p>
                    <p className="mt-3 text-skin-muted text-sm">
                        March 05, 2022
                    </p>
               </Link>
            </div>
        </div>
    </div>
      ))
  )
}

const AllArticle = ({ data }) => {
  return (
      data.map((node, i) => (
      <div key={i}>
        <Link to={`/article/${node.slug}`}>
          <img src={node.image != null ? node.image : "" } alt="placeholder" className="mb-6" />
          <div>
              <h1 className="text-lg font-medium mb-3">{node.title_en}</h1>
              <p>
                {node.subtitle_en}
              </p>
              <p className="mt-3 text-base text-sm">
              {node.created_at}
              </p>
          </div>
        </Link>
      </div>
      ))
  )   
}
export {PrimaryArticle, SecondaryArticle, AllArticle}